import React, { useState } from "react"
import Img from "gatsby-image"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./gallery.scss"

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Gallery = ({ images }) => {
  const [popupData, setPopupData] = useState(null)
  const [current, setCurrent] = useState(0)
  //slider mechanism
  function SlideRight(i, e) {
    e.preventDefault()
    const index = images.length > i + 1 ? i + 1 : 0
    setPopupData({
      image: images[index].image.childImageSharp.fluid,
      caption: images[index].caption,
      alt: images[index].caption,
    })
    setCurrent(index)
  }
  //for left
  function SlideLeft(i, e) {
    e.preventDefault()
    const index = i < 1 ? images.length - 1 : i - 1
    setPopupData({
      image: images[index].image.childImageSharp.fluid,
      caption: images[index].caption,
      alt: images[index].caption,
    })
    setCurrent(index)
  }
  //setting current image index in state
  function openPopup(i, d) {
    setCurrent(i)
    setPopupData({
      image: d.image.childImageSharp.fluid,
      caption: d.caption,
      alt: d.caption,
    })
  }
  return (
    <div className="gallery">
      <Slider {...settings}>
        {images.map((d, i) => {
          return (
            <div className="item" onClick={() => openPopup(i, d)} key={d.id}>
              <div className="image">
                <Img
                  imgStyle={{
                    width: `100%`,
                    height: `100%`,
                    objectFit: `cover`,
                  }}
                  fluid={d.image.childImageSharp.fluid}
                  alt={d.caption}
                />
              </div>
              <h4 className="caption">{d.caption}</h4>
            </div>
          )
        })}
      </Slider>
      {popupData && (
        <div className="gallery-popup" onClick={() => setPopupData(null)}>
          <button
            className="close-popup"
            /*onClick={() => setPopupData(null)}*/
          >
            <svg role="presentation" viewBox="0 0 24 24">
              <path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path>
            </svg>
          </button>
          <div className="image">
            <div onClick={e => e.stopPropagation()}>
              <Img
                imgStyle={{
                  position: `fixed`,
                  width: `auto`,
                  height: `auto`,
                  left: `50%`,
                  top: `50%`,
                  transform: `translate(-50%, -50%)`,
                  objectFit: `cover`,
                }}
                fluid={popupData.image}
                alt={popupData.caption}
              />
            </div>
          </div>
          <div className="text">
            <h4>{popupData.caption}</h4>
          </div>

          {/* Navigation Start */}
          <nav>
            <ul>
              <li onClick={e => e.stopPropagation()}>
                <a
                  onClick={e => SlideLeft(current, e)}
                  className="fa fa-chevron-left"
                />
              </li>
              <li onClick={e => e.stopPropagation()}>
                <a
                  href="#"
                  onClick={e => SlideRight(current, e)}
                  className="fa fa-chevron-right"
                />
              </li>
            </ul>
          </nav>
          {/* Navigation End */}
        </div>
      )}
    </div>
  )
}

export default Gallery
