import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NewGallery from "../components/gallery"
import Markdown from "markdown-to-jsx"
import Img from "gatsby-image"

import { useStaticQuery, graphql } from "gatsby"

export default ({ pageContext: { post } }) => {
  //console.log(post)
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  console.log(post)
  return (
    <Layout>
      <SEO title={post.meta_title} description={post.meta_description} />
      {/*<ListContainer>*/}
      <section className="jumbotron breadcumb">
        <div className="mainbreadcumb">
          <div className="row m-10-hor page-title">
            <div className="col-md-12">
              <ol /* itemscope itemtype="https://schema.org/BreadcrumbList" */>
                <li
                /* itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem" */
                >
                  <Link
                    to="/"
                    title={data.site.siteMetadata.description}
                    // itemprop="item"
                  >
                    <span>{data.site.siteMetadata.title}</span>
                  </Link>
                  {/*<meta itemprop="position" content="1" />*/}
                </li>
                {/*<span className="dash">/</span>*/}
                <li
                /* itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem" */
                >
                  <span /* itemprop="name" */>{post.breadcrumbs_title}</span>

                  {/* <meta itemprop="position" content="2" /> */}
                </li>
              </ol>
              <h1>{post.title}</h1>
            </div>
          </div>
        </div>
        <div id="cover">
          <Img
            imgStyle={{
              width: `100%`,
              height: `100%`,
              objectFit: `cover`,
              position: `absolute`,
            }}
            fluid={post.cover.childImageSharp.fluid}
            alt={post.meta_title}
          />
        </div>
      </section>
      {post.content.map((d, i) => {
        return <Slice key={i} d={d} />
      })}
    </Layout>
  )
}

//slice
function Slice({ host, d }) {
  if (d.html != null) {
    return (
      <section
        className={d.class}
        dangerouslySetInnerHTML={{ __html: d.html }}
      />
    )
  } else if (d.richtext != null) {
    return (
      <section className={d.class}>
        <div className="row m-10-hor">
          <article className="col-md-7 mx-auto">
            <Markdown>{d.richtext}</Markdown>
          </article>
        </div>
      </section>
    )
  } else if (d.images != null) {
    return (
      <section className={d.class} style={{ overflow: "hidden" }}>
        <div className="row m-10-hor">
          <NewGallery images={d.images} />
        </div>
      </section>
    )
  } else {
    return null
  }
}
